.search-input {
  input {
    border: none;
    border-bottom: 1px solid;
    padding: 6px;
    font-size: 16px;
    width: 100%; }

  button {
    i {
      font-size: 22px; } } }
