.content-card {
  background: white;

  .content__left {
    width: 72%; }

  .content__right {
    width: 20%; } }

@media ( max-width: 768px ) {
  .content__left {
    width: 100% !important; }

  .content__right {
    width: 100% !important; } }
