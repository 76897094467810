@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');

.ebook-content {
  padding-left: 96px;
  padding-right: 96px;

  .ebook-title {
    font-size: 60px;
    font-family: 'Bowlby One SC';
    color:#00b0f2 {}
    text-align: center;
    span {
      text-shadow: 5px 5px #000000cc; } }

  .ebook-call {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 8px;

    .ebook-call-list {
      margin-right: 8px;
      text-align: right;
      width: 50%;
      font-size: 20px;
      font-family: 'Bowlby One SC';
      .ebook-list {
        color: #00b0f2;
        padding: 0px;
        list-style: none;
        li {
          font-weight: lighter;
          margin-bottom: 4px; } } } }

  .ebook-cover {
    margin-left: 8px;
    width: 50%;

    img {
      border-radius: 5%;
      width: 40%;
      box-shadow: 2px 2px 5px; } }

  .ebook-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin: auto;
    margin-top: 8px;

    p {
        margin-bottom: 32px; }

    .ebook-button {
      width: 50%; } }

  .ebook-games-list {
    list-style: none;
    li:first-child {
      margin-bottom: 32px; }
    img {
      width: 32px;
      margin-right: 8px; }
    a {
      font-family: 'Bowlby One SC';
      text-decoration: none;
      color: black; } }


  .ebook-sub-title {
    font-size: 30px;
    font-family: 'Bowlby One SC';
    color:black {}
    text-align: center; }

  .ebook-card {
    padding: 24px;
    font-weight: 400;
    .ebook-text-highlight {
      font-weight: 800;
      color: #FF7F50;
      font-size: 22px; }
    .ebook-highlight-dark {
      font-weight: 800; }
    .ebook-chapters {
      list-style: none;
      padding: 0;
      li {
        margin-bottom: 4px;
        span {
          font-weight: 800;
          color: #00b0f2; } } } }

  .ebook-title-imgs {
    margin: auto;
    justify-content: center;
    .ebook-title-imgs-ogl {
      width: 150px;
      margin-right: 32px; }
    .ebook-title-imgs-cpp {
      width: 75px; } }

  .ebook-iframe {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 560px;

    iframe {
      width: 100%;
      height: 315px; }
    img {
      width: 100%; } }

  .ebook-button-text {
    margin-top: 4px;
    margin-bottom: 4px; }

  .ebook-offer {
    color: #FF7F50;
    .ebook-price {
      text-decoration: line-through; } }

  .ebook-examples {
    display: flex;
    align-items: center;
    flex-direction: column;
    h1 {
      margin-top: 0px;
      margin-bottom: 0px; } } }


@media screen and ( max-width: 1300px ) {
  .ebook-content {
    padding-left: 20px;
    padding-right: 20px;

    .ebook-text {
      width: 80%; } } }

@media screen and ( max-width: 768px ) {
  .ebook-content {
    padding-left: 2px;
    padding-right: 2px;


    .ebook-title {
      font-size: 30px;
      font-family: 'Bowlby One SC';
      color:#00b0f2 {}
      text-align: center;
      span {
        text-shadow: 5px 5px #000000cc; } }

    .ebook-call {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 8px;

      .ebook-call-list {
        margin-right: 8px;
        text-align: center;
        width: 100%; } }

    .ebook-text {
      width: 100%;
      .ebook-card {
        padding: 2px; }
      .ebook-button {
        width: 100%;
        margin-bottom: 1em; } }

    p {
      margin: 4px; }

    .ebook-iframe {
      display: flex;
      width: 100%;
      max-width: 280px;
      iframe {
        width: 100%;
        height: 157px; }
      img {
        width: 100%; } }

    .ebook-cover {
      margin-left: 8px;
      width: 100%;
      text-align: center;
      img {
        border-radius: 5%;
        width: 40%;
        box-shadow: 2px 2px 5px; } } } }
