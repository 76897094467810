.post {
  padding-left: 162px;
  padding-right: 162px;
  padding-top: 32px;

  .post__title {
    h1 {
      margin-bottom: 2px; }
    .post__date {
      font-size: 14px; } }

  .post__goback {
    text-align: right;
    a {
      cursor: pointer;
      text-decoration: none;
      color: black;
      font-weight: 800;
      span {
        &:hover {
          text-decoration: underline; } } }

    .post__goback_button {
      width: 32px;
      height: 32px;
      background-color: #3b5998;
      border-radius: 4px;

      display: inline-flex;
      align-items: center;
      justify-content: center;

      margin-right: 4px;

      font-size: 22px; } }

  .post__tags_title {
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 8px; } }


@media screen and ( max-width: 768px ) {
  .post {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px; } }
