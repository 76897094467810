.post-preview {
  .post-preview__image {
    width: 30%;
    min-width: 128px;
    cursor: pointer;
    .post-preview__image__place {
      background-size: cover;
      background-position: center;
      width: 100%;
      min-height: 128px; } }

  .post-preview__data {
    position: relative;

    .post-preview__data__text {
      font-size: 18px; }

    .post-preview__data__title {
      font-size: 24px;
      font-weight: 600;
      cursor: pointer; }

    .post-preview__data__tags {
      font-style: italic;
      font-weight: 600;
      cursor: pointer; }

    .post-preview__data__postedBy {
      span {
        cursor: pointer; }

      .post-preview__data__author {
        font-weight: 600; }

      .post-preview__data__update {
        font-weight: 600; } } } }

@media screen and ( max-width: 768px ) {
  .post-preview__data__text {
    font-size: 16px !important; }

  .post-preview__data__title {
    font-size: 20px !important; } }
