.about-content {
  padding: 28px; }

.eu {
  max-width: 256px;
  max-height: 256px;
  width: 60%;
  border-radius: 4px; }

.workana-hero {
  width: 32px;
  height: 32px; }

.hoverable {
  cursor: pointer; }

.about-link {
  text-decoration: none;
  color: black; }

.dimension-m-title {
  margin-bottom: 8px; }

.dimension-m {
  height: 128px;
  margin-right: 8px;
  float: left; }

@media screen and ( max-width: 768px ) {
  .about-content {
    padding: 8px !important; } }

