.paginator {
  .paginator__button {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    i {
      font-size: 18px;
      margin: 4px; } }

  .paginator__left {
    &:hover {
      background: #efefef;
      border-radius: 40px; } }

  .paginator__right {
    &:hover {
      background: #efefef;
      border-radius: 40px; } }

  .paginator__page {
    font-weight: 600;
    font-size: 24px;
    color: white;
    border-radius: 2px;
    background: #3b5998; }

  .paginator__text_button {
    display: inherit; } }

@media screen and ( max-width: 768px ) {
  .paginator__button {
    i {
      font-size: 26px !important;
      margin: 4px !important; }

    .paginator__text_button {
      display: none !important; } } }
