.blog-links {
  .blog-link {
    a {
      text-decoration: none;
      color: black;
      display: flex;
      align-items: center;

      i {
        font-size: 24px;
        margin-right: 4px; } } } }
