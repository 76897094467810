.hotmart-button-link {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  background: linear-gradient(to top,#9ccc3a 0,#5cad4a 80%);
  border: 1px solid #5cad4a;
  border-radius: 3px;
  color: white !important;
  font-family: Helvetica,Arial,sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-shadow: 0 1px 0 rgba(0,0,0,.7);
  box-shadow: inset 1px 1px 0 0 #cdeb8e;
  transition: all 1s cubic-bezier(.2,1,.22,1);
  box-sizing: border-box;
  line-height: 1.5;
  display: inline-block;
  text-decoration: none; }
