.header-labels {
  a {
    text-decoration: none;
    color: white;
    cursor: pointer;
    &:hover {
      color: #3b5998; } }

  h2 {
    margin: 0;
    display: inline; } }
