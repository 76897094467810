.header {
  height: 124px;
  background-color: rgb(40, 40, 40);
  border-bottom: 8px solid #3b5998;
  padding-left: 20%;
  padding-right: 20%;

  transition: height 2s;

  &.expand {
    height: 100%; }

  .header__title {
    a {
      text-decoration: none; }
    h2 {
      color: white;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; } } }

@media screen and ( max-width: 768px ) {
  .header {
    padding-left: 8px;
    padding-right: 8px; } }

