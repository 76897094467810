.post-list__empty {
  font-size: 22px;
  font-weight: 800;
  i {
    color: #3b5998;
    margin: 8px;
    font-size: 28px; } }

@media only screen and (max-width: 768px) {
  .post-preview-div {
    width: 100% !important; } }
